<template>
  <div>
    <section>
      <h1 class="visually-hidden">DrDoctor Web Simplificator</h1>
      <article>
        <div style="padding-bottom: 20px">
          <h2 style="display: inline">Patient Details</h2>
          <AppDashboardProvider
            style="display: inline; float: right"
            v-on:providerChanged="changeProvider"
          />
        </div>
        <div class="card" v-if="profile">
          <div
            class="card-body card-grid profile"
            :class="profile.isArchived ? 'inactive' : 'active'"
          >
            <p class="profile-label">Active</p>
            <p class="profile-detail active-switch">
              <AppToggleProfileActive
                v-bind:id="profile.patientId"
                v-bind:active="!profile.isArchived"
                v-bind:sourceSystemId="profile.sourceSystemId"
                :key="`${profile.sourceSystemId}-active`"
              />
            </p>
            <p class="profile-label">Patient ID</p>
            <p class="profile-detail">
              <AppCopyToClipboard v-bind:text="profile.patientId" />
            </p>
            <p class="profile-label">MRN</p>
            <p class="profile-detail">
              <AppCopyToClipboard v-bind:text="profile.mrn" />
            </p>
            <p class="profile-label">First name</p>
            <p class="profile-detail">{{ profile.firstName }}</p>
            <p class="profile-label">Last name</p>
            <p class="profile-detail">{{ profile.lastName }}</p>
            <p class="profile-label">Date of birth</p>
            <AppDashboardDateofBirth
              v-bind:dateOfBirth="profile.dateOfBirth"
              v-bind:sourceSystemId="profile.sourceSystemId"
              :key="`${profile.sourceSystemId}-dateOfBirth`"
            />
            <p class="profile-label">NHS Number</p>
            <AppDashboardNhsNumber
              v-bind:nhsNumber="profile.nhsNumber"
              v-bind:sourceSystemId="profile.sourceSystemId"
              :key="`${profile.sourceSystemId}-nhsNumber`"
            />
            <p class="profile-label" v-if="profile.postcodes">Postcode</p>
            <ul class="profile-detail" v-if="profile.postcodes">
              <li
                v-for="postcode in profile.postcodes"
                :key="postcode.patientContactId"
              >
                <AppDashboardPostcode
                  v-bind:postcode="postcode"
                  v-bind:sourceSystemId="profile.sourceSystemId"
                  :key="`${profile.sourceSystemId}-postcode`"
                />
              </li>
              <li>
                <AppDashboardAddPostcode
                  v-bind:sourceSystemId="profile.sourceSystemId"
                  :key="`${profile.sourceSystemId}-addPostcode`"
                />
              </li>
            </ul>
            <p class="profile-label" v-if="profile.emails">Email</p>
            <ul class="profile-detail" v-if="profile.emails">
              <li v-for="email in profile.emails" :key="email.patientContactId">
                <AppDashboardEmail
                  v-bind:email="email"
                  v-bind:isNew="email.isNew"
                  v-bind:sourceSystemId="profile.sourceSystemId"
                  :key="`${profile.sourceSystemId}-addEmail`"
                />
              </li>
              <li>
                <AppDashboardAddEmail
                  v-bind:sourceSystemId="profile.sourceSystemId"
                  :key="`${profile.sourceSystemId}-email`"
                />
              </li>
            </ul>
            <p class="profile-label">Mobile numbers</p>
            <ul class="profile-detail">
              <li v-for="mobile in profile.mobiles" :key="mobile">
                <AppDashboardMobile
                  v-bind:mobile="mobile"
                  v-bind:isNew="mobile.isNew"
                  v-bind:sourceSystemId="profile.sourceSystemId"
                  :key="`${profile.sourceSystemId}-addMobile`"
                />
              </li>
              <li>
                <AppDashboardAddMobile
                  v-bind:sourceSystemId="profile.sourceSystemId"
                  :key="`${profile.sourceSystemId}-mobile`"
                />
              </li>
            </ul>
            <p class="profile-label">Date of death</p>
            <AppDashboardDateOfDeath
              v-bind:dateOfDeath="profile.dateOfDeath"
              v-bind:sourceSystemId="profile.sourceSystemId"
              :key="`${profile.sourceSystemId}-dateOfDeath`"
            />
          </div>
        </div>
        <SkeletonLoader
          v-else
          :cols="['115px', '1fr']"
          :rows="8"
          :gap="['1rem', '2rem']"
          :height="385"
          :custom-classes="['card']"
          :widths="{ 2: 42, 4: 295 }"
        />
      </article>
    </section>
    <AppDashboardLetterUpload
      v-if="profile"
      v-bind:sourceSystemId="profile.sourceSystemId"
      :key="`${profile.sourceSystemId}-letterUpload`"
    />
    <AppDashboardAppointments
      v-if="profile && !appointmentIdToEdit"
      v-bind:sourceSystemId="profile.sourceSystemId"
      :key="`${profile.sourceSystemId}-appointments`"
    />
    <AppDashboardEditAppointment
      v-if="appointmentIdToEdit"
      v-bind:appointmentId="appointmentIdToEdit"
      v-bind:sourceSystemId="profile.sourceSystemId"
      :key="`${profile.sourceSystemId}-editAppointments`"
    />
    <AppDashboardAssessments
      v-if="profile"
      v-bind:sourceSystemId="profile.sourceSystemId"
      :key="`${profile.sourceSystemId}-assessments`"
    />
    <AppDashboardWaitingList
      v-if="profile"
      v-bind:sourceSystemId="profile.sourceSystemId"
      :key="`${profile.sourceSystemId}-waitinglists`"
    />
  </div>
</template>

<script>
import AppDashboardMobile from "@/components/AppDashboardMobile.vue";
import AppDashboardAddMobile from "@/components/AppDashboardAddMobile.vue";
import AppDashboardEmail from "@/components/AppDashboardEmail.vue";
import AppDashboardAddEmail from "@/components/AppDashboardAddEmail.vue";
import AppDashboardDateofBirth from "@/components/AppDashboardDateOfBirth.vue";
import AppDashboardNhsNumber from "@/components/AppDashboardNhsNumber.vue";
import AppDashboardPostcode from "@/components/AppDashboardPostcode.vue";
import AppDashboardAddPostcode from "./AppDashboardAddPostcode.vue";
import AppDashboardDateOfDeath from "@/components/AppDashboardDateOfDeath.vue";
import AppDashboardAppointments from "@/components/AppDashboardAppointments.vue";
import AppDashboardLetterUpload from "@/components/AppDashboardLetterUpload.vue";
import AppDashboardAssessments from "@/components/AppDashboardAssessments.vue";
import AppDashboardEditAppointment from "@/components/AppDashboardEditAppointment.vue";
import AppDashboardProvider from "@/components/AppDashboardProvider.vue";
import AppDashboardWaitingList from "@/components/AppDashboardWaitingList.vue";

import AppCopyToClipboard from "@/components/AppCopyToClipboard.vue";
import AppToggleProfileActive from "@/components/AppToggleProfileActive.vue";
import SkeletonLoader from "@/components/SkeletonLoader.vue";

const ContactId = {
  MOBILE: 1,
  ADDRESS: 5,
  EMAIL: 6,
};

export default {
  components: {
    AppDashboardMobile,
    AppDashboardAddMobile,
    AppDashboardEmail,
    AppDashboardAddEmail,
    AppDashboardAppointments,
    AppDashboardEditAppointment,
    AppDashboardAssessments,
    AppDashboardLetterUpload,
    AppDashboardDateofBirth,
    AppDashboardNhsNumber,
    AppDashboardPostcode,
    AppDashboardAddPostcode,
    AppDashboardDateOfDeath,
    AppDashboardWaitingList,
    AppCopyToClipboard,
    AppToggleProfileActive,
    SkeletonLoader,
    AppDashboardProvider,
  },
  name: "AppDashboard",
  props: { account: Object },
  data() {
    return {
      containers: [],
      profile: undefined,
      appointmentIdToEdit: undefined,
      selectedProvider: -1,
    };
  },
  mounted() {
    this.getProfile();

    this.$emitter.on("providerChanged"),
      (selectedProvider) => {
        this.selectedProvider = selectedProvider;
      };

    this.$emitter.on("mobileAdded", (contact) => {
      this.profile.mobiles.push({ ...contact, isNew: true });
    });

    this.$emitter.on("mobileNumberDeleted", (contact) => {
      this.profile.mobiles = this.profile.mobiles.filter(
        (mobile) => mobile.patientContactId !== contact.patientContactId,
      );
    });

    this.$emitter.on("emailAddressAdded", (contact) => {
      this.profile.emails.push({ ...contact, isNew: true });
    });

    this.$emitter.on("emailAddressDeleted", (contact) => {
      this.profile.emails = this.profile.emails.filter(
        (email) => email.patientContactId !== contact.patientContactId,
      );
    });

    this.$emitter.on("postcodeAdded", (contact) => {
      this.profile.postcodes.push({ ...contact, isNew: true });
    });

    this.$emitter.on("postcodeDeleted", (contact) => {
      this.profile.postcodes = this.profile.postcodes.filter(
        (postcode) => postcode.patientContactId !== contact.patientContactId,
      );
    });

    this.$emitter.on("onProfileActiveToggled", (isArchived) => {
      this.profile.isArchived = isArchived;
    });
    this.$emitter.on("onEditAppointment", (appointmentId) => {
      this.appointmentIdToEdit = appointmentId;
    });
    this.$emitter.on("onAppointmentEdited", () => {
      this.appointmentIdToEdit = undefined;
    });
  },
  methods: {
    async getProfile() {
      const url = `./api/me/${this.selectedProvider}`;
      const bearer = "Bearer " + this.$store.state.accessToken;

      fetch(url, {
        method: "GET",
        withCredentials: true,
        credentials: "include",
        headers: {
          Authorization: bearer,
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error("Something went wrong");
          }
        })
        .then((responseJson) => {
          this.profile = responseJson;
          this.getPostcodes();
          this.getMobileNumbers();
          this.getEmail();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getPostcodes() {
      if (this.profile) {
        if (this.profile.contacts && this.profile.contacts.length) {
          this.profile.postcodes = this.profile.contacts.filter(
            (c) => c.contactTypeId === ContactId.ADDRESS,
          );
        }
      }
    },
    getMobileNumbers() {
      if (this.profile) {
        if (this.profile.contacts && this.profile.contacts.length) {
          this.profile.mobiles = this.profile.contacts.filter(
            (c) => c.contactTypeId === ContactId.MOBILE,
          );
        }
      }
    },
    getEmail() {
      if (this.profile) {
        if (this.profile.contacts && this.profile.contacts.length) {
          this.profile.emails = this.profile.contacts.filter(
            (c) => c.contactTypeId === ContactId.EMAIL,
          );
        }
      }
    },
    changeProvider(selectedProvider) {
      this.selectedProvider = selectedProvider;
      this.getProfile();
    },
  },
};
</script>

<style scoped lang="scss">
@import "./.././styles/abstracts/_variables.scss";
@import "./.././styles/abstracts/_mixins.scss";

section {
  margin: $spacer-5 auto;
  max-width: $max-page-width;
  width: calc(100% - #{$spacer * 2});
}

.profile {
  .profile-label {
    margin-bottom: $spacer-2;
  }

  .profile-detail {
    margin-bottom: $spacer-4;
  }

  @include respond-to(mobile-l) {
    .profile-label,
    .profile-detail {
      margin-bottom: 0;
    }
  }

  &.inactive {
    .profile-detail:not(.active-switch) {
      opacity: 0.5;
      pointer-events: none;
    }
  }
}

.profile-label {
  grid-column: 1;
  color: var(--text-muted);
}

.profile-detail:not(ul) {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  grid-column: 2;
  font-weight: 600;

  .theme-dark & {
    font-weight: 400;
  }
}

li {
  display: flex;
  margin-bottom: $spacer-2;
  width: 100%;

  &:last-of-type {
    margin: 0;
  }

  p {
    font-weight: 600;
    margin: 0;

    .theme-dark & {
      font-weight: 400;
    }
  }
}
</style>
