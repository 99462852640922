<template>
  <section>
    <h2>Appointments</h2>
    <article class="card">
      <div class="card-body card-grid">
        <h3>Upcoming</h3>
        <ul v-if="appointments.future && appointments.future.length">
          <li
            v-for="appointment in appointments.future"
            :key="appointment.appointmentId"
          >
            <p>
              {{ formatDateToBeReadable(appointment.appointmentDateTime) }}
              (<AppCopyToClipboard
                displayText="ID"
                v-bind:text="appointment.appointmentId"
              />) (<a
                :href="appointment.link"
                target="_blank"
                title="View in Patient Portal"
                >Link</a
              >) (<button
                class="button-link text-small"
                @click="edit(appointment.appointmentId)"
              >
                Edit</button
              >) (<button
                class="button-link text-small"
                @click="sendSecondaryReminder(appointment.appointmentId)"
                :disabled="secondaryReminderTriggered"
                v-show="secondaryReminderTriggered != appointment.appointmentId"
              >
                Secondary reminder</button
              ><span
                class="text-small"
                v-if="secondaryReminderTriggered == appointment.appointmentId"
                >Reminder triggered!</span
              >) (<button
                class="button-link text-small"
                @click="
                  makeAutomatedConfirmationCall(appointment.appointmentId)
                "
                :disabled="confirmationCallTriggered"
                v-show="confirmationCallTriggered != appointment.appointmentId"
              >
                Confirmation call</button
              ><span
                class="text-small"
                v-if="confirmationCallTriggered == appointment.appointmentId"
                >Call triggered!</span
              >)
            </p>
          </li>
        </ul>
        <p v-else>No future appointments</p>
        <h3>Past</h3>
        <ul v-if="appointments.past && appointments.past.length">
          <li
            v-for="appointment in appointments.past"
            :key="appointment.appointmentId"
          >
            <p>
              {{ formatDateToBeReadable(appointment.appointmentDateTime) }}
              (<AppCopyToClipboard
                displayText="ID"
                v-bind:text="appointment.appointmentId"
              />) (<a
                :href="appointment.link"
                target="_blank"
                title="View in Patient Portal"
                >Link</a
              >) (<button
                class="button-link text-small"
                @click="edit(appointment.appointmentId)"
              >
                Edit</button
              >)
            </p>
          </li>
        </ul>
        <p v-else>No past appointments</p>
        <h3>Reset</h3>
        <button @click="resetData" v-bind:disabled="isLoading">
          Reset appointment data
        </button>
      </div>
    </article>
  </section>
</template>

<script>
import { formatDateToBeReadable } from "./../utils/DateUtils.js";
import AppCopyToClipboard from "@/components/AppCopyToClipboard.vue";

export default {
  components: {
    AppCopyToClipboard,
  },
  name: "AppDashboardAppointments",
  props: {
    sourceSystemId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      appointments: {},
      isLoading: false,
      confirmationCallTriggered: undefined,
      secondaryReminderTriggered: undefined,
    };
  },
  mounted() {
    this.getAppointments();
  },
  methods: {
    resetData() {
      this.isLoading = true;

      const url = `./api/me/resetdata/${this.sourceSystemId}`;
      const bearer = "Bearer " + this.$store.state.accessToken;

      const data = {};

      fetch(url, {
        method: "POST",
        withCredentials: true,
        credentials: "include",
        headers: {
          Authorization: bearer,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((response) => {
          if (response.ok) {
            this.getAppointments();
          } else {
            this.isLoading = false;
            throw new Error("Something went wrong");
          }
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
    getAppointments() {
      const url = `./api/me/allAppointments/${this.sourceSystemId}`;
      const bearer = "Bearer " + this.$store.state.accessToken;

      fetch(url, {
        method: "GET",
        withCredentials: true,
        credentials: "include",
        headers: {
          Authorization: bearer,
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            this.isLoading = false;
            throw new Error("Something went wrong");
          }
        })
        .then((responseJson) => {
          this.appointments = this.sortAppointmentsByTime(responseJson);
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          console.log(error);
        });
    },
    sortAppointmentsByTime(appointmentData) {
      const past = [];
      const future = [];
      const now = new Date();

      appointmentData.filter((a) => {
        +new Date(a.appointmentDateTime) < +now ? past.push(a) : future.push(a);
      });

      past.sort(
        (a, b) =>
          new Date(b.appointmentDateTime) - new Date(a.appointmentDateTime),
      );
      future.sort(
        (a, b) =>
          new Date(b.appointmentDateTime) - new Date(a.appointmentDateTime),
      );

      return { past, future };
    },
    formatDateToBeReadable,
    edit(appointmentId) {
      this.$emitter.emit("onEditAppointment", appointmentId);
    },
    makeAutomatedConfirmationCall(appointmentId) {
      this.isLoading = true;
      this.confirmationCallTriggered = appointmentId;

      const url = `./api/me/appointment/automatedConfirmationCall/${appointmentId}`;
      const bearer = "Bearer " + this.$store.state.accessToken;

      fetch(url, {
        method: "POST",
        withCredentials: true,
        credentials: "include",
        headers: {
          Authorization: bearer,
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (response.ok) {
            this.isLoading = false;
            setTimeout(() => {
              this.confirmationCallTriggered = undefined;
            }, 10000);
          } else {
            this.isLoading = false;
            this.confirmationCallTriggered = undefined;
            throw new Error("Something went wrong");
          }
        })
        .catch((error) => {
          this.isLoading = false;
          this.confirmationCallTriggered = undefined;
          console.log(error);
        });
    },
    sendSecondaryReminder(appointmentId) {
      this.isLoading = true;
      this.secondaryReminderTriggered = appointmentId;

      const url = `./api/me/appointment/sendSecondaryReminder/${appointmentId}`;
      const bearer = "Bearer " + this.$store.state.accessToken;

      fetch(url, {
        method: "POST",
        withCredentials: true,
        credentials: "include",
        headers: {
          Authorization: bearer,
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (response.ok) {
            this.isLoading = false;
            setTimeout(() => {
              this.secondaryReminderTriggered = undefined;
            }, 10000);
          } else {
            this.isLoading = false;
            this.secondaryReminderTriggered = undefined;
            throw new Error("Something went wrong");
          }
        })
        .catch((error) => {
          this.isLoading = false;
          this.secondaryReminderTriggered = undefined;
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./.././styles/abstracts/_variables.scss";

a {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}
</style>
